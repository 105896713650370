/**
 * Transaction process graph for bookings:
 *   - default-booking
 */

import { defaultTransactionProcessAlias } from '../config/configListing';

/**
 * Transitions
 *
 * These strings must sync with values defined in Marketplace API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

export const transitions = {
  // When a customer makes a booking to a listing, a transaction is
  // created with the initial request-payment transition.
  // At this transition a PaymentIntent is created by Marketplace API.
  // After this transition, the actual payment must be made on client-side directly to Stripe.
  REQUEST_PAYMENT: 'transition/request-payment',

  // A customer can also initiate a transaction with an inquiry, and
  // then transition that with a request.
  INQUIRE: 'transition/inquire',
  REQUEST_PAYMENT_AFTER_INQUIRY: 'transition/request-payment-after-inquiry',

  // Stripe SDK might need to ask 3D security from customer, in a separate front-end step.
  // Therefore we need to make another transition to Marketplace API,
  // to tell that the payment is confirmed.
  CONFIRM_PAYMENT: 'transition/confirm-payment',
  REQUEST_REPAYMENT_AFTER_PREAUTHORIZED: 'transition/request-repayment-after-preauthorized',
  CONFIRM_PAYMENT_AFTER_PREAUTHORIZED: 'transition/confirm-payment-after-preauthorized',
  CANCEL_REPAYMENT_AFTER_PREAUTHORIZED: 'transition/cancel-repayment-after-preauthorized',

  // If the payment is not confirmed in the time limit set in transaction process (by default 15min)
  // the transaction will expire automatically.
  EXPIRE_PAYMENT: 'transition/expire-payment',

  // When the provider accepts or declines a transaction from the
  // SalePage, it is transitioned with the accept or decline transition.
  ACCEPT: 'transition/accept',
  DECLINE: 'transition/decline',

  REQUEST_REPAYMENT_AFTER_ACCEPTED: 'transition/request-repayment-after-accepted',
  CONFIRM_PAYMENT_AFTER_ACCEPTED: 'transition/confirm-payment-after-accepted',
  CANCEL_REPAYMENT_AFTER_ACCEPTED: 'transition/cancel-repayment-after-accepted',
  PARTIAL_REFUND: 'transition/partial-refund',

  // The operator can accept or decline the offer on behalf of the provider
  OPERATOR_ACCEPT: 'transition/operator-accept',
  OPERATOR_DECLINE: 'transition/operator-decline',

  // DISPUTE related transitions
  DISPUTE_BY_CUSTOMER: 'transition/dispute-by-customer',
  DISPUTE_BY_PROVIDER: 'transition/dispute-by-provider',
  PARTIAL_REFUND_AFTER_DISPUTED: 'transition/partial-refund-after-disputed',
  DISPUTE_BY_CUSTOMER_AFTER_APPROVAL: 'transition/dispute-by-customer-after-approval',
  DISPUTE_BY_PROVIDER_AFTER_APPROVAL: 'transition/dispute-by-provider-after-approval',
  DISPUTE_FROM_CANCELED_BY_PROVIDER: 'transition/dispute-from-canceled-by-provider',
  DISPUTE_FROM_CANCELED_BY_CUSTOMER: 'transition/dispute-from-canceled-by-customer',
  UNCANCEL_BY_PROVIDER_BACK_TO_DISPUTED: 'transition/uncancel-by-provider-back-to-disputed',
  UNCANCEL_BY_CUSTOMER_BACK_TO_DISPUTED: 'transition/uncancel-by-customer-back-to-disputed',
  CONFIRM_PAYMENT_AFTER_DISPUTED: 'transition/confirm-payment-after-disputed',
  REQUEST_REPAYMENT_AFTER_DISPUTED: 'transition/request-repayment-after-disputed',

  // MEDIATION related transitions
  AUTO_MEDIATE: 'transition/auto-mediate',
  CONFIRM_PAYMENT_AFTER_MEDIATION: 'transition/confirm-payment-after-mediation',
  REQUEST_REPAYMENT_AFTER_MEDIATION: 'transition/request-repayment-after-mediation',
  OPERATOR_MEDIATE: 'transition/operator-mediate',
  CANCEL_FROM_MEDIATION: 'transition/operator-cancel-from-mediation',
  RESOLVE_FROM_MEDIATION: 'transition/operator-resolve-from-mediation',

  APPROVAL_1_BY_CUSTOMER_AFTER_ACCEPTED: 'transition/approval-1-by-customer-after-accepted',
  APPROVAL_1_BY_PROVIDER_AFTER_ACCEPTED: 'transition/approval-1-by-provider-after-accepted',
  APPROVAL_1_BY_CUSTOMER_AFTER_DISPUTED: 'transition/approval-1-by-customer-after-disputed',
  APPROVAL_1_BY_PROVIDER_AFTER_DISPUTED: 'transition/approval-1-by-provider-after-disputed',
  APPROVAL_2_BY_CUSTOMER_AFTER_ACCEPTED: 'transition/approval-2-by-customer-after-accepted',
  APPROVAL_2_BY_PROVIDER_AFTER_ACCEPTED: 'transition/approval-2-by-provider-after-accepted',
  APPROVAL_2_BY_CUSTOMER_AFTER_DISPUTED: 'transition/approval-2-by-customer-after-disputed',
  APPROVAL_2_BY_PROVIDER_AFTER_DISPUTED: 'transition/approval-2-by-provider-after-disputed',
  AUTO_APPROVAL_2_AS_CUSTOMER: 'transition/auto-approval-2-as-customer',
  AUTO_APPROVAL_2_AS_PROVIDER: 'transition/auto-approval-2-as-provider',
  AUTO_MEDIATE_AS_CUSTOMER: 'transition/auto-mediate-as-customer',
  AUTO_MEDIATE_AS_PROVIDER: 'transition/auto-mediate-as-provider',
  DISPUTE_BY_CUSTOMER_AFTER_APPROVAL_AFTER_ACCEPTED: 'transition/dispute-by-customer-after-approval-after-accepted',
  DISPUTE_BY_PROVIDER_AFTER_APPROVAL_AFTER_ACCEPTED: 'transition/dispute-by-provider-after-approval-after-accepted',
  DISPUTE_BY_CUSTOMER_AFTER_APPROVAL_AFTER_DISPUTED: 'transition/dispute-by-customer-after-approval-after-disputed',
  DISPUTE_BY_PROVIDER_AFTER_APPROVAL_AFTER_DISPUTED: 'transition/dispute-by-provider-after-approval-after-disputed',

  CANCEL_1_BY_PROVIDER_AFTER_DISPUTED: 'transition/cancel-1-by-provider-after-disputed',
  CANCEL_1_BY_CUSTOMER_AFTER_DISPUTED: 'transition/cancel-1-by-customer-after-disputed',
  CANCEL_2_BY_PROVIDER: 'transition/cancel-2-by-provider',
  CANCEL_2_BY_CUSTOMER: 'transition/cancel-2-by-customer',
  AUTO_CANCEL_2_AS_PROVIDER: 'transition/auto-cancel-2-as-provider',
  AUTO_CANCEL_2_AS_CUSTOMER: 'transition/auto-cancel-2-as-customer',
  DISPUTE_BY_PROVIDER_AFTER_CANCELED: 'transition/dispute-by-provider-after-canceled',
  DISPUTE_BY_CUSTOMER_AFTER_CANCELED: 'transition/dispute-by-customer-after-canceled',

  // The backend automatically expire the transaction.
  EXPIRE: 'transition/expire',

  // Admin can also cancel the transition.
  CANCEL: 'transition/cancel',
  CANCEL_BY_CUSTOMER_AFTER_PREAUTHORIZED: 'transition/cancel-by-customer-after-preauthorized',
  CANCEL_BY_PROVIDER_AFTER_ACCEPTED_BEFORE_BOOKING_STARTS: 'transition/cancel-by-provider-after-accepted-before-booking-starts',
  CANCEL_BY_CUSTOMER_AFTER_ACCEPTED_BEFORE_BOOKING_STARTS: 'transition/cancel-by-customer-after-accepted-before-booking-starts',
  CANCEL_1_BY_PROVIDER_AFTER_ACCEPTED_AFTER_BOOKING_STARTS: 'transition/cancel-1-by-provider-after-accepted-after-booking-starts',
  CANCEL_1_BY_CUSTOMER_AFTER_ACCEPTED_AFTER_BOOKING_STARTS: 'transition/cancel-1-by-customer-after-accepted-after-booking-starts',
  UNCANCEL_BY_PROVIDER_AFTER_ACCEPTED: 'transition/uncancel-by-provider-after-accepted',
  UNCANCEL_BY_CUSTOMER_AFTER_ACCEPTED: 'transition/uncancel-by-customer-after-accepted',
  UNCANCEL_BY_PROVIDER_AFTER_DISPUTED: 'transition/uncancel-by-provider-after-disputed',
  UNCANCEL_BY_CUSTOMER_AFTER_DISPUTED: 'transition/uncancel-by-customer-after-disputed',

  // The backend will mark the transaction completed.
  COMPLETE: 'transition/complete',
  OPERATOR_COMPLETE: 'transition/operator-complete',

  // Reviews are given through transaction transitions. Review 1 can be
  // by provider or customer, and review 2 will be the other party of
  // the transaction.
  REVIEW_1_BY_PROVIDER: 'transition/review-1-by-provider',
  REVIEW_2_BY_PROVIDER: 'transition/review-2-by-provider',
  REVIEW_1_BY_CUSTOMER: 'transition/review-1-by-customer',
  REVIEW_2_BY_CUSTOMER: 'transition/review-2-by-customer',
  EXPIRE_CUSTOMER_REVIEW_PERIOD: 'transition/expire-customer-review-period',
  EXPIRE_PROVIDER_REVIEW_PERIOD: 'transition/expire-provider-review-period',
  EXPIRE_REVIEW_PERIOD: 'transition/expire-review-period',
};


/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
export const states = {
  INITIAL: 'initial',
  INQUIRY: 'inquiry',
  PENDING_PAYMENT: 'pending-payment',
  PAYMENT_EXPIRED: 'payment-expired',
  PREAUTHORIZED: 'preauthorized',
  DECLINED: 'declined',
  ACCEPTED: 'accepted',
  APPROVED_BY_CUSTOMER_AFTER_ACCEPTED: 'approved-by-customer-after-accepted',
  APPROVED_BY_PROVIDER_AFTER_ACCEPTED: 'approved-by-provider-after-accepted',
  DISPUTED: 'disputed',
  APPROVED_BY_CUSTOMER_AFTER_DISPUTED: 'approved-by-customer-after-disputed',
  APPROVED_BY_PROVIDER_AFTER_DISPUTED: 'approved-by-provider-after-disputed',
  PENDING_PAYMENT_AFTER_DISPUTED: 'pending-payment-after-disputed',
  PENDING_PAYMENT_AFTER_MEDIATION: 'pending-payment-after-mediation',

  CANCELLED_BY_CUSTOMER: 'cancelled-by-customer',
  CANCELLED_BY_PROVIDER: 'cancelled-by-provider',
  MEDIATION: 'mediation',
  EXPIRED: 'expired',
  PENDING_PAYMENT_AFTER_PREAUTHORIZED: 'pending-payment-after-preauthorized',
  PENDING_PAYMENT_AFTER_ACCEPTED: 'pending-payment-after-accepted',
  CANCELED: 'canceled',
  CANCELED_BY_PROVIDER: 'canceled-by-provider',
  CANCELED_BY_CUSTOMER: 'canceled-by-customer',
  DELIVERED: 'delivered',
  REVIEWED: 'reviewed',
  REVIEWED_BY_CUSTOMER: 'reviewed-by-customer',
  REVIEWED_BY_PROVIDER: 'reviewed-by-provider',
};

/**
 * Description of transaction process graph
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
export const graph = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: `default-booking/${defaultTransactionProcessAlias}`,

  // This 'initial' state is a starting point for new transaction
  initial: states.INITIAL,

  // States
  states: {
    [states.INITIAL]: {
      on: {
        [transitions.INQUIRE]: states.INQUIRY,
        [transitions.REQUEST_PAYMENT]: states.PENDING_PAYMENT,
      },
    },
    [states.INQUIRY]: {
      on: {
        [transitions.REQUEST_PAYMENT_AFTER_INQUIRY]: states.PENDING_PAYMENT,
      },
    },

    [states.PENDING_PAYMENT]: {
      on: {
        [transitions.EXPIRE_PAYMENT]: states.PAYMENT_EXPIRED,
        [transitions.CONFIRM_PAYMENT]: states.PREAUTHORIZED,
      },
    },

    [states.PAYMENT_EXPIRED]: {},
    [states.PREAUTHORIZED]: {
      on: {
        [transitions.REQUEST_REPAYMENT_AFTER_PREAUTHORIZED]: states.PENDING_PAYMENT_AFTER_PREAUTHORIZED,
        [transitions.DECLINE]: states.DECLINED,
        [transitions.OPERATOR_DECLINE]: states.DECLINED,
        [transitions.EXPIRE]: states.EXPIRED,
        [transitions.CANCEL_BY_CUSTOMER_AFTER_PREAUTHORIZED]: states.CANCELED,
        [transitions.ACCEPT]: states.ACCEPTED,
        [transitions.OPERATOR_ACCEPT]: states.ACCEPTED,
      },
    },
    [states.PENDING_PAYMENT_AFTER_PREAUTHORIZED]: {
      on: {
        [transitions.CANCEL_REPAYMENT_AFTER_PREAUTHORIZED]: states.PREAUTHORIZED,
        [transitions.CONFIRM_PAYMENT_AFTER_PREAUTHORIZED]: states.PREAUTHORIZED,
      }
    },
    [states.DECLINED]: {},
    [states.EXPIRED]: {},
    [states.ACCEPTED]: {
      on: {
        [transitions.CANCEL]: states.CANCELED,
        [transitions.COMPLETE]: states.DELIVERED,
        [transitions.OPERATOR_COMPLETE]: states.DELIVERED,
        [transitions.REQUEST_REPAYMENT_AFTER_ACCEPTED]: states.PENDING_PAYMENT_AFTER_ACCEPTED,
        [transitions.DISPUTE_BY_CUSTOMER]: states.DISPUTED,
        [transitions.DISPUTE_BY_PROVIDER]: states.DISPUTED,
        [transitions.CANCEL_BY_PROVIDER_AFTER_ACCEPTED_BEFORE_BOOKING_STARTS]: states.CANCELED,
        [transitions.CANCEL_BY_CUSTOMER_AFTER_ACCEPTED_BEFORE_BOOKING_STARTS]: states.CANCELED,
        [transitions.CANCEL_1_BY_PROVIDER_AFTER_ACCEPTED_AFTER_BOOKING_STARTS]: states.CANCELED_BY_PROVIDER,
        [transitions.CANCEL_1_BY_CUSTOMER_AFTER_ACCEPTED_AFTER_BOOKING_STARTS]: states.CANCELED_BY_CUSTOMER,
        [transitions.PARTIAL_REFUND]: states.ACCEPTED,
        [transitions.APPROVAL_1_BY_CUSTOMER_AFTER_ACCEPTED]: states.APPROVED_BY_CUSTOMER_AFTER_ACCEPTED,
        [transitions.APPROVAL_1_BY_PROVIDER_AFTER_ACCEPTED]: states.APPROVED_BY_PROVIDER_AFTER_ACCEPTED,
      },
    },

    [states.PENDING_PAYMENT_AFTER_ACCEPTED]: {
      on: {
        [transitions.CANCEL_REPAYMENT_AFTER_ACCEPTED]: states.ACCEPTED,
        [transitions.CONFIRM_PAYMENT_AFTER_ACCEPTED]: states.ACCEPTED,
      },
    },
    [states.APPROVED_BY_PROVIDER_AFTER_ACCEPTED]: {
      on: {
        [transitions.APPROVAL_2_BY_CUSTOMER_AFTER_ACCEPTED]: states.DELIVERED,
        [transitions.AUTO_APPROVAL_2_AS_CUSTOMER]: states.DELIVERED,
        [transitions.DISPUTE_BY_CUSTOMER_AFTER_APPROVAL_AFTER_ACCEPTED]: states.DISPUTED,
      },
    },
    [states.APPROVED_BY_CUSTOMER_AFTER_ACCEPTED]: {
      on: {
        [transitions.APPROVAL_2_BY_PROVIDER_AFTER_ACCEPTED]: states.DELIVERED,
        [transitions.AUTO_APPROVAL_2_AS_PROVIDER]: states.DELIVERED,
        [transitions.DISPUTE_BY_PROVIDER_AFTER_APPROVAL_AFTER_ACCEPTED]: states.DISPUTED,
      },
    },
    [states.DISPUTED]: {
      on: {
        [transitions.AUTO_MEDIATE]: states.MEDIATION,
        [transitions.OPERATOR_MEDIATE]: states.MEDIATION,
        [transitions.CANCEL_1_BY_PROVIDER_AFTER_DISPUTED]: states.CANCELED_BY_PROVIDER,
        [transitions.CANCEL_1_BY_CUSTOMER_AFTER_DISPUTED]: states.CANCELED_BY_CUSTOMER,
        [transitions.APPROVAL_1_BY_CUSTOMER_AFTER_DISPUTED]: states.APPROVED_BY_CUSTOMER_AFTER_DISPUTED,
        [transitions.APPROVAL_1_BY_PROVIDER_AFTER_DISPUTED]: states.APPROVED_BY_PROVIDER_AFTER_DISPUTED,
        [transitions.CANCEL_FROM_DISPUTED]: states.CANCELED,
        [transitions.MARK_RECEIVED]: states.DELIVERED,
        [transitions.PARTIAL_REFUND_AFTER_DISPUTED]: states.DISPUTED,
        [transitions.REQUEST_REPAYMENT_AFTER_DISPUTED]: states.PENDING_PAYMENT_AFTER_DISPUTED,
      },
    },
    [states.APPROVED_BY_PROVIDER_AFTER_DISPUTED]: {
      on: {
        [transitions.APPROVAL_2_BY_CUSTOMER_AFTER_DISPUTED]: states.DELIVERED,
        [transitions.AUTO_MEDIATE_AS_CUSTOMER]: states.MEDIATION,
        [transitions.DISPUTE_BY_CUSTOMER_AFTER_APPROVAL_AFTER_DISPUTED]: states.DISPUTED,
      },
    },
    [states.APPROVED_BY_CUSTOMER_AFTER_DISPUTED]: {
      on: {
        [transitions.APPROVAL_2_BY_PROVIDER_AFTER_DISPUTED]: states.DELIVERED,
        [transitions.AUTO_MEDIATE_AS_PROVIDER]: states.MEDIATION,
        [transitions.DISPUTE_BY_PROVIDER_AFTER_APPROVAL_AFTER_DISPUTED]: states.DISPUTED,
      },
    },
    [states.PENDING_PAYMENT_AFTER_DISPUTED]: {
      on: {
        [transitions.CONFIRM_PAYMENT_AFTER_DISPUTED]: states.DISPUTED,
      }
    },
    [states.MEDIATION]: {
      on: {
        [transitions.CANCEL_FROM_MEDIATION]: states.CANCELED,
        [transitions.RESOLVE_FROM_MEDIATION]: states.DELIVERED,
        [transitions.REQUEST_REPAYMENT_AFTER_MEDIATION]: states.PENDING_PAYMENT_AFTER_MEDIATION,
      },
    },
    [states.PENDING_PAYMENT_AFTER_MEDIATION]: {
      on: {
        [transitions.CONFIRM_PAYMENT_AFTER_MEDIATION]: states.MEDIATION,
      }
    },
    [states.CANCELED]: {},
    [states.CANCELED_BY_PROVIDER]: {
      on: {
        [transitions.CANCEL_2_BY_CUSTOMER]: states.CANCELED,
        [transitions.AUTO_CANCEL_2_AS_CUSTOMER]: states.CANCELED,
        [transitions.DISPUTE_BY_CUSTOMER_AFTER_CANCELED]: states.DISPUTED,
        [transitions.UNCANCEL_BY_PROVIDER_AFTER_ACCEPTED]: states.ACCEPTED,
        [transitions.UNCANCEL_BY_PROVIDER_AFTER_DISPUTED]: states.DISPUTED,
      },
    },
    [states.CANCELED_BY_CUSTOMER]: {
      on: {
        [transitions.CANCEL_2_BY_PROVIDER]: states.CANCELED,
        [transitions.AUTO_CANCEL_2_AS_PROVIDER]: states.CANCELED,
        [transitions.DISPUTE_BY_PROVIDER_AFTER_CANCELED]: states.DISPUTED,
        [transitions.UNCANCEL_BY_CUSTOMER_AFTER_ACCEPTED]: states.ACCEPTED,
        [transitions.UNCANCEL_BY_CUSTOMER_AFTER_DISPUTED]: states.DISPUTED,
      },
    },
    [states.DELIVERED]: {
      on: {
        [transitions.EXPIRE_REVIEW_PERIOD]: states.REVIEWED,
        [transitions.REVIEW_1_BY_CUSTOMER]: states.REVIEWED_BY_CUSTOMER,
        [transitions.REVIEW_1_BY_PROVIDER]: states.REVIEWED_BY_PROVIDER,
      },
    },

    [states.REVIEWED_BY_CUSTOMER]: {
      on: {
        [transitions.REVIEW_2_BY_PROVIDER]: states.REVIEWED,
        [transitions.EXPIRE_PROVIDER_REVIEW_PERIOD]: states.REVIEWED,
      },
    },
    [states.REVIEWED_BY_PROVIDER]: {
      on: {
        [transitions.REVIEW_2_BY_CUSTOMER]: states.REVIEWED,
        [transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD]: states.REVIEWED,
      },
    },
    [states.REVIEWED]: { type: 'final' },
  },
};

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    transitions.ACCEPT,
    transitions.DISPUTE_BY_CUSTOMER,
    transitions.DISPUTE_BY_PROVIDER,
    transitions.AUTO_MEDIATE,
    transitions.OPERATOR_MEDIATE,
    transitions.CANCEL_FROM_MEDIATION,
    transitions.RESOLVE_FROM_MEDIATION,
    transitions.APPROVAL_1_BY_CUSTOMER_AFTER_ACCEPTED,
    transitions.APPROVAL_1_BY_PROVIDER_AFTER_ACCEPTED,
    transitions.APPROVAL_1_BY_CUSTOMER_AFTER_DISPUTED,
    transitions.APPROVAL_1_BY_PROVIDER_AFTER_DISPUTED,
    transitions.APPROVAL_2_BY_CUSTOMER_AFTER_ACCEPTED,
    transitions.APPROVAL_2_BY_PROVIDER_AFTER_ACCEPTED,
    transitions.APPROVAL_2_BY_CUSTOMER_AFTER_DISPUTED,
    transitions.APPROVAL_2_BY_PROVIDER_AFTER_DISPUTED,
    transitions.AUTO_APPROVAL_2_AS_CUSTOMER,
    transitions.AUTO_APPROVAL_2_AS_PROVIDER,
    transitions.AUTO_MEDIATE_AS_CUSTOMER,
    transitions.AUTO_MEDIATE_AS_PROVIDER,
    transitions.DISPUTE_BY_CUSTOMER_AFTER_APPROVAL_AFTER_ACCEPTED,
    transitions.DISPUTE_BY_PROVIDER_AFTER_APPROVAL_AFTER_ACCEPTED,
    transitions.DISPUTE_BY_CUSTOMER_AFTER_APPROVAL_AFTER_DISPUTED,
    transitions.DISPUTE_BY_PROVIDER_AFTER_APPROVAL_AFTER_DISPUTED,
    transitions.CANCEL_1_BY_PROVIDER_AFTER_DISPUTED,
    transitions.CANCEL_1_BY_CUSTOMER_AFTER_DISPUTED,
    transitions.CANCEL_2_BY_PROVIDER,
    transitions.CANCEL_2_BY_CUSTOMER,
    transitions.AUTO_CANCEL_2_AS_PROVIDER,
    transitions.AUTO_CANCEL_2_AS_CUSTOMER,
    transitions.DISPUTE_BY_PROVIDER_AFTER_CANCELED,
    transitions.DISPUTE_BY_CUSTOMER_AFTER_CANCELED,
    transitions.OPERATOR_ACCEPT,
    transitions.CANCEL,
    transitions.CANCEL_BY_CUSTOMER_AFTER_PREAUTHORIZED,
    transitions.CANCEL_BY_PROVIDER_AFTER_ACCEPTED_BEFORE_BOOKING_STARTS,
    transitions.CANCEL_BY_CUSTOMER_AFTER_ACCEPTED_BEFORE_BOOKING_STARTS,
    transitions.CANCEL_1_BY_PROVIDER_AFTER_ACCEPTED_AFTER_BOOKING_STARTS,
    transitions.CANCEL_1_BY_CUSTOMER_AFTER_ACCEPTED_AFTER_BOOKING_STARTS,
    transitions.UNCANCEL_BY_PROVIDER_AFTER_ACCEPTED,
    transitions.UNCANCEL_BY_CUSTOMER_AFTER_ACCEPTED,
    transitions.UNCANCEL_BY_PROVIDER_AFTER_DISPUTED,
    transitions.UNCANCEL_BY_CUSTOMER_AFTER_DISPUTED,
    transitions.COMPLETE,
    transitions.OPERATOR_COMPLETE,
    transitions.CONFIRM_PAYMENT,
    transitions.CONFIRM_PAYMENT_AFTER_PREAUTHORIZED,
    transitions.DECLINE,
    transitions.OPERATOR_DECLINE,
    transitions.EXPIRE,
    transitions.REVIEW_1_BY_CUSTOMER,
    transitions.REVIEW_1_BY_PROVIDER,
    transitions.REVIEW_2_BY_CUSTOMER,
    transitions.REVIEW_2_BY_PROVIDER,
    transitions.PARTIAL_REFUND,
    transitions.CONFIRM_PAYMENT_AFTER_DISPUTED,
    transitions.CONFIRM_PAYMENT_AFTER_ACCEPTED,
    transitions.CONFIRM_PAYMENT_AFTER_MEDIATION,
  ].includes(transition);
};
// Processes might be different on how reviews are handled.
// Default processes use two-sided diamond shape, where either party can make the review first
export const isCustomerReview = transition => {
  return [transitions.REVIEW_1_BY_CUSTOMER, transitions.REVIEW_2_BY_CUSTOMER].includes(transition);
};

// Processes might be different on how reviews are handled.
// Default processes use two-sided diamond shape, where either party can make the review first
export const isProviderReview = transition => {
  return [transitions.REVIEW_1_BY_PROVIDER, transitions.REVIEW_2_BY_PROVIDER].includes(transition);
};

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
  // TODO (jayen): CANCEL_BY_*_AFTER_ACCEPTED_BEFORE_BOOKING_STARTS transitions should be privileged so the backend can check the booking didn't start yet
  return [
    transitions.REQUEST_PAYMENT,
    transitions.REQUEST_PAYMENT_AFTER_INQUIRY,
    transitions.REQUEST_REPAYMENT_AFTER_PREAUTHORIZED,
    transitions.REQUEST_REPAYMENT_AFTER_ACCEPTED,
    transitions.REQUEST_REPAYMENT_AFTER_DISPUTED,
    transitions.REQUEST_REPAYMENT_AFTER_MEDIATION,
  ].includes(
    transition
  );
};

// Check when transaction is completed (booking over)
export const isCompleted = transition => {
  const txCompletedTransitions = [
    transitions.APPROVAL_2_BY_CUSTOMER_AFTER_ACCEPTED,
    transitions.APPROVAL_2_BY_PROVIDER_AFTER_ACCEPTED,
    transitions.APPROVAL_2_BY_CUSTOMER_AFTER_DISPUTED,
    transitions.APPROVAL_2_BY_PROVIDER_AFTER_DISPUTED,
    transitions.AUTO_APPROVAL_2_AS_CUSTOMER,
    transitions.AUTO_APPROVAL_2_AS_PROVIDER,
    transitions.COMPLETE,
    transitions.OPERATOR_COMPLETE,
    transitions.REVIEW_1_BY_CUSTOMER,
    transitions.REVIEW_1_BY_PROVIDER,
    transitions.REVIEW_2_BY_CUSTOMER,
    transitions.REVIEW_2_BY_PROVIDER,
    transitions.EXPIRE_REVIEW_PERIOD,
    transitions.EXPIRE_CUSTOMER_REVIEW_PERIOD,
    transitions.EXPIRE_PROVIDER_REVIEW_PERIOD,
  ];
  return txCompletedTransitions.includes(transition);
};

// Present jobs (the transition is accepted or preauthorized)

export const getAllFutureTransitionsForBookingProcess = () => {
  const futureStates = [
    states.INQUIRY,
    states.PENDING_PAYMENT,
    states.PREAUTHORIZED,
  ];
  return getIncomingTransitions(futureStates);
};
export const getAllPastTransitionsForBookingProcess = () => {
  const pastStates = [
    states.PAYMENT_EXPIRED,
    states.DECLINED,
    states.EXPIRED,
    states.CANCELED,
    states.DELIVERED,
    states.REVIEWED,
    states.REVIEWED_BY_CUSTOMER,
    states.REVIEWED_BY_PROVIDER,
  ];
  const pastTransitions = getIncomingTransitions(pastStates);
  return pastTransitions;
}
export const getAllPresentTransitionsForBookingProcess = transition => {
  const allTransitions = Object.values(transitions);
  const pastTransitions = getAllPastTransitionsForBookingProcess();
  const futureTransitions = getAllFutureTransitionsForBookingProcess();
  return allTransitions.filter(t => ![...pastTransitions, ...futureTransitions].includes(t));
};

// Check when transaction is refunded (booking did not happen)
// In these transitions action/stripe-refund-payment is called
export const isRefunded = transition => {
  const txRefundedTransitions = [
    transitions.EXPIRE_PAYMENT,
    transitions.CANCEL_2_BY_PROVIDER,
    transitions.CANCEL_2_BY_CUSTOMER,
    transitions.AUTO_CANCEL_2_AS_PROVIDER,
    transitions.AUTO_CANCEL_2_AS_CUSTOMER,
    transitions.EXPIRE,
    transitions.CANCEL,
    transitions.CANCEL_BY_CUSTOMER_AFTER_PREAUTHORIZED,
    transitions.CANCEL_BY_PROVIDER_AFTER_ACCEPTED_BEFORE_BOOKING_STARTS,
    transitions.CANCEL_BY_CUSTOMER_AFTER_ACCEPTED_BEFORE_BOOKING_STARTS,
    transitions.DECLINE,
  ];
  return txRefundedTransitions.includes(transition);
};

export const statesNeedingProviderAttention = [states.PREAUTHORIZED, states.APPROVED_BY_CUSTOMER_AFTER_ACCEPTED, states.APPROVED_BY_CUSTOMER_AFTER_DISPUTED, states.CANCELED_BY_CUSTOMER];

function getIncomingTransitions(states) {
  return states.reduce((transitions, state) => {
    // for each source state, get all transitions that lead to `state`
    for (const [s, { on }] of Object.entries(graph.states)) {
      if (on) {
        for (const [t, target] of Object.entries(on)) {
          if (target === state) {
            transitions.push(t);
          }
        }
      }
    }
    return transitions;
  }, []);
}

