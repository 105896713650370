import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes, LINE_ITEM_HOUR, LINE_ITEMS } from '../../util/types';
import { resolveLatestProcessName, getProcess } from '../../transactions/transaction';
import { humanizeLineItemCode } from '../../util/data';

import { Tooltip } from 'react-tooltip'

import LineItemTotalPrice from './LineItemTotalPrice';

import { estimatedTotalPrice } from '../OrderPanel/EstimatedCustomerBreakdownMaybe';

import css from './OrderBreakdown.module.css';

const tooltipContent = {
    "provider": {
        "payProviderPrivatelyText": 
        ` The Customer has selected to pay you privately outside of the Blinkn platform. <br /> If you think this is a mistake or if you wish to be paid on the platform,
         please contact the customer before accepting the booking request. This can be done via the message
        box to the left. <br /> The booking fee is still required to be paid in order to verify the job and receive reviews.`,

        "youOweText": `To verify the booking request you must pay the platform booking fee. 
        <br />
        This is the only fee you will pay to Blinkn for this booking. 
        This fee helps us to run our platform and provide you with the 
        best possible service.`,
    }, 
    "customer": {
        "payProviderPrivatelyText": 
        `By Selecting this option you are agreeing to pay the provider privately outside of the Blinkn platform. <br /> You must have the Providers permission 
        before selecting this option. <br /> The booking fee is still required to be paid in order to verify the job and receive reviews.`,
        "youOweText": `To verify the booking request you must pay the platform booking fee. <br />
        This is the only fee you will pay to Blinkn for this booking. 
        This fee helps us to run our platform and provide you with the 
        best possible service.`,
    },
}

const LineItemBlinknBreakdownMaybe = props => {
    const { lineItems, transaction, isCashPaidLater, intl, isProvider, code, isIncGST } = props;

    const processName = resolveLatestProcessName(transaction?.attributes?.processName);

    if (!processName) {
        return null;
    }

    const process = getProcess(processName);

    // LineItemsUnknownItemsMaybe.js
    // resolve unknown non-reversal line items
    const allItems = lineItems.filter(item => LINE_ITEMS.indexOf(item.code) === -1 && !item.reversal);
    const items = isProvider
    ? allItems.filter(item => item.includeFor.includes('provider'))
    : allItems.filter(item => item.includeFor.includes('customer'));


    const payProviderPrivatelyItem = items.find(item => item.code === 'line-item/cash-paid-later' && item.quantity > 0);

    let payProviderPrivatelyLabel = payProviderPrivatelyItem ? humanizeLineItemCode(payProviderPrivatelyItem.code) : null;
    if (payProviderPrivatelyLabel && payProviderPrivatelyLabel.toLowerCase().includes("cash paid later")) {
        payProviderPrivatelyLabel = isProvider ? "Customer to pay privately" : "Pay Provider Privately";
    }
    const payProviderPrivatelyFormattedTotal = payProviderPrivatelyItem ? formatMoney(intl, payProviderPrivatelyItem.lineTotal) : null;
    

    const bookingFee = lineItems.find(item => item.code === 'line-item/booking-fee' && item.quantity > 0);
    let bookingFeeLabel = bookingFee ? humanizeLineItemCode(bookingFee.code) : null;
    const bookingFeeFormattedTotal = bookingFee ? formatMoney(intl, bookingFee.lineTotal) : null;



    const isNightly = code === LINE_ITEM_NIGHT;
    const isDaily = code === LINE_ITEM_DAY;
    const isHourly = code === LINE_ITEM_HOUR;
    const translationKey = isNightly
        ? 'OrderBreakdown.baseUnitNight'
        : isDaily
            ? 'OrderBreakdown.baseUnitDay'
            : isHourly
                ? 'OrderBreakdown.baseUnitHour'
                : 'OrderBreakdown.baseUnitQuantity';

    const unitPurchase = lineItems.find(item => item.code === code && item.quantity > 0);

    const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;
    const unitPrice = unitPurchase ? formatMoney(intl, unitPurchase.unitPrice) : null;
    const total = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null;



    return isCashPaidLater ? (
        <>
        {/* $80 * 7hours                   $560 */}
            <div className={css.lineItem}>
                <span className={css.itemLabel}>
                    <FormattedMessage id={`${translationKey}${isIncGST ? '.incGST' : ''}`} values={{ unitPrice, quantity }} />
                </span>
                <span className={css.itemValue}>{total}</span>
            </div>
        {/* Booking fee                     $10 */}
        <div className={css.lineItem}>
            <span className={css.itemLabel}>{bookingFeeLabel}</span>

            <span className={css.itemValue}> {bookingFeeFormattedTotal}</span>
        </div>


        {/* Total                           $570 */}
        <LineItemTotalPrice lineItems={lineItems} transaction={transaction} isProvider={isProvider} intl={intl} isCashPaidLater={isCashPaidLater}/>


        
        {/* Pay Provider Privately */}
        <div className={css.lineItem}>
            <div className={css.tooltipParent}>
                <span className={css.itemLabel} style={{marginRight: "4px"}}>{payProviderPrivatelyLabel}</span>
                <a data-tooltip-id="my-tooltip" data-tooltip-html={tooltipContent[isProvider ? "provider": "customer"]["payProviderPrivatelyText"]}>
                    ?
                </a>
                <Tooltip id="my-tooltip" className={css.tooltip} />
            </div>
            

            <span className={css.itemValue}>{payProviderPrivatelyFormattedTotal}</span>
        </div>

        <hr className={css.totalDivider} />

        {/* You owe */}
        <div className={css.lineItem}>
            
           <div className={css.tooltipParent}>
                <span className={css.itemLabel} style={{marginRight: "4px"}}>You owe today</span>
                <a data-tooltip-id="my-tooltip" data-tooltip-html={tooltipContent[isProvider ? "provider": "customer"]["youOweText"]}>
                ?
                </a>
                <Tooltip id="my-tooltip" className={css.tooltip} />
           </div>

            <span className={css.youOweBold}>{bookingFeeFormattedTotal}</span>
        </div>
        </>
    ) : null;
}


export default LineItemBlinknBreakdownMaybe;